import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from 'store/actions';
import Input from 'components/atoms/Inputs/Input';
import SelectInput from 'components/atoms/Inputs/SelectInput';
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/atoms/Buttons/ButtonPrimary';
import { Validators } from 'components/atoms/Inputs/Validators';
import Cookies from 'js-cookie';

const Wrapper = styled.div`
  display: block;
  margin: 16px auto;
  min-width: 120px;
  width: 100%;
  height: auto;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`;

const FormWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  background-color: #ffffff;
  color: #fff;
  z-index: 1;
  width: 100%;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const SingleInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const ErrorMsg = styled.div`
  margin-top: 3px;
  margin-left: 14px;
`;

const initialState = {
  email: '',
  userPassword: '',
  confirmPassword: '',
  dietType: '',
  daysQty: '',
};

const dietsList = [
  { value: 1, label: 'Keto' },
  { value: 2, label: 'Zbilansowana' },
  { value: 3, label: 'Dla cukrzyków' },
  { value: 4, label: 'Post przerywany' },
];

const daysList = [
  { value: 30, label: '30 dni' },
  { value: 90, label: '90 dni' },
  { value: 180, label: '180 dni' },
];

const AddUsers = ({ onAddUsersForm, setVisible, unieqeEmails }) => {
  const [inputsValue, setInputValue] = useState(initialState);
  const [error, setError] = useState(false);

  const handleInputChange = e => {
    setInputValue({
      ...inputsValue,
      [e.target.name]: e.target.value,
    });
  };

  const setDietsType = e => {
    setInputValue({
      ...inputsValue,
      dietType: e.value,
    });
  };

  const setDayQty = e => {
    setInputValue({
      ...inputsValue,
      daysQty: e.value,
    });
  };

  const resetForm = () => {
    setVisible(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    fetch('https://api.flexiketo.app/api/users/withDiet', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.getJSON('authKey')}`,
      },
      body: JSON.stringify({
        email: inputsValue.email,
        password: inputsValue.userPassword,
        dietType: inputsValue.dietType,
        daysQty: inputsValue.daysQty,
      }),
    });
    // onAddUsersForm(
    //   inputsValue.email,
    //   inputsValue.userPassword,
    //   inputsValue.dietType,
    //   inputsValue.daysQty,
    // );
  };

  const passwordValidation = () => {
    if (inputsValue.userPassword === inputsValue.confirmPassword) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Wrapper>
      <Typography variant="h5" color="primary">
        Dodaj użytkownika z dietą
      </Typography>
      <FormWrapper>
        <StyledForm onSubmit={e => handleSubmit(e)}>
          <SingleInputWrapper>
            <Input
              id="email"
              uniqueNames={unieqeEmails}
              onChange={handleInputChange}
              value={inputsValue.email}
              htmlFor={inputsValue.email}
              label="E-mail"
              name="email"
              type="email"
            />
          </SingleInputWrapper>

          <SingleInputWrapper>
            <Input
              id="userPassword"
              onChange={handleInputChange}
              value={inputsValue.userPassword}
              htmlFor="userPassword"
              label="Nowe hasło"
              name="userPassword"
              type="password"
            />
          </SingleInputWrapper>

          <SingleInputWrapper>
            <SelectInput
              options={dietsList}
              // val={isEnabled}
              noOptionsText="Nie znaleziono"
              label="wybierz dietę"
              handleOnChange={setDietsType}
              onChangeParams={['unit']}
              name="dietType"
              id="dietType"
            />
          </SingleInputWrapper>

          <SingleInputWrapper>
            <SelectInput
              options={daysList}
              // val={isEnabled}
              noOptionsText="Nie znaleziono"
              label="wybierz ilość"
              handleOnChange={setDayQty}
              onChangeParams={['unit']}
              name="daysQty"
              id="daysQty"
            />
          </SingleInputWrapper>

          <ButtonWrapper>
            <ButtonPrimary type="submit"> Dodaj Użytkownika</ButtonPrimary>
          </ButtonWrapper>
        </StyledForm>
      </FormWrapper>
    </Wrapper>
  );
};

export default AddUsers;
